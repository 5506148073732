import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import {
    Carousel,
} from "react-bootstrap";
import ReactMarkdown from "react-markdown"
import Layout from '../../../components/layout'
import { BackgroundImages } from "../../../shared";
import { bgBlog } from '../../../images';
import { Helmet } from "react-helmet"

const PortfolioTemplate = ({ data }) => (
  <Layout sitePage="site-page" pageInfo={{ pageName: "Portfolio" }}>
    <Helmet defer={false}>
        <title>{data.strapiPortfolio.Title}</title>
        <meta name="description" content={data.strapiPortfolio.Title} />
    </Helmet>
    <div className="wrapper-sub-portfolio">
      <div className="header-image-sub-portfolio">
        <Img fixed={data.strapiPortfolio.image_header.childImageSharp.fixed} alt="900x500" className="image-header-portfolio lazyload" />
        <div className="bg-color-image"></div>
      </div>
      <h1 className="title-sub-portfolio">{data.strapiPortfolio.Title}</h1>
      <div className="box-sub-portfolio">
        <div className="left-sub-portfolio">
          <ReactMarkdown className="content-sub-portfolio" source={data.strapiPortfolio.content_1} />
        </div>
        <div className="right-sub-portfolio">
          <Img fixed={data.strapiPortfolio.image_content_1.childImageSharp.fixed} alt="900x500" className="image-sub-portfolio lazyload" />
        </div>
      </div>
      <div className="box-sub-portfolio">
        <div className="left-sub-portfolio left">
          <ReactMarkdown className="content-sub-portfolio" source={data.strapiPortfolio.content_2} />
        </div>
        <div className="right-sub-portfolio right">
          <Img fixed={data.strapiPortfolio.image_content_2.childImageSharp.fixed} alt="900x500" className="image-sub-portfolio lazyload" />
        </div>
      </div>
    </div>

    <div className="wrapper-slide-sub-portfolio">
      <Carousel className="slide-sub-portfolio">
        <Carousel.Item>
          <Img fixed={data.strapiPortfolio.slide_1.childImageSharp.fixed} alt="900x500" className="slide-image-sub-portfolio lazyload" />
        </Carousel.Item>
        <Carousel.Item>
          <Img fixed={data.strapiPortfolio.slide_2.childImageSharp.fixed} alt="900x500" className="slide-image-sub-portfolio lazyload" />
        </Carousel.Item>
        <Carousel.Item>
          <Img fixed={data.strapiPortfolio.slide_3.childImageSharp.fixed} alt="900x500" className="slide-image-sub-portfolio lazyload" />
        </Carousel.Item>
      </Carousel>
      <BackgroundImages style={{
        backgroundImage: `url(${bgBlog})`,
        zIndex: -1
      }}>
        <div className="box-header" />
      </BackgroundImages>
    </div>
  </Layout>
)

export default PortfolioTemplate

export const query = graphql`
  query PortfolioTemplate($url: String) {
    strapiPortfolio(url: {eq: $url}) {
      Title
      content_1
      content_2
      image_header {
        childImageSharp {
          fixed(width: 2000) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      image_content_1 {
        childImageSharp {
          fixed(width: 2000) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      image_content_2 {
        childImageSharp {
          fixed(width: 2000) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      slide_1 {
        childImageSharp {
          fixed(width: 2000) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      slide_2 {
        childImageSharp {
          fixed(width: 2000) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      slide_3 {
        childImageSharp {
          fixed(width: 2000) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  }
`
